import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  Typography,
  Grid
} from "@material-ui/core";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { getFaqs } from "config/faqs";
import { FuseAnimateGroup } from "components/fuse";
import { useStyles } from "./styles";
import FaqContext from "./FaqContext";


const FaqContent = ({ filteredData }) => {

  const { scroll: { scrollTo } } = useContext(UiStateContext);

  const { envVars } = useContext(SessionContext);


  const faqRef = useRef(null);

  const scrolled = useRef(false);


  const { query: { faqId } } = useRouter();


  const faqs = useMemo(() => getFaqs(envVars), [envVars]);

  const intFaqId = useMemo(() => faqId && parseInt(clsx(faqId)), [faqId]);

  const loadedFaqId = useMemo(() => intFaqId && intFaqId <= faqs.length ? intFaqId : false, [faqs, intFaqId]);


  const [expanded, setExpanded] = useState(loadedFaqId);


  const openExpansion = useCallback(id => setExpanded(id || 0), []);

  const toggleExpansion = useCallback(panel => (event, expanded) => setExpanded(expanded ? panel : false), []);


  useEffect(() => {
    if (!scrolled.current && faqRef.current && scrollTo) {
      scrolled.current = true;

      const { offsetTop } = faqRef.current;
      scrollTo(0, offsetTop - 16);
    }
  }, [scrollTo]);


  const classes = useStyles();


  const contextValue = {
    openExpansion,
  };


  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col w-full max-w-xl py-24 sm:py-32">

        {
          (filteredData.length === 0) && (
            <div className="flex flex-auto items-center justify-center w-full h-full mt-16">
              <Typography color="textSecondary" variant="h5">
                Nincs a keresésnek megfelelő találat
              </Typography>
            </div>
          )
        }

        <Grid container className="px-16 sm:px-24">

          <Grid item xs={12}>
            <FuseAnimateGroup
              enter={{
                animation: "transition.slideUpBigIn"
              }}
            >
              {Boolean(filteredData) && filteredData.map(faq => (

                <ExpansionPanel
                  classes={{
                    root: clsx("w-full", classes.panel),
                    expanded: classes.expanded,
                  }}
                  key={faq.id}
                  ref={panel => {
                    if (faq.id === intFaqId) faqRef.current = panel;
                  }}
                  expanded={expanded === faq.id}
                  onChange={toggleExpansion(faq.id)}
                  elevation={0}
                >

                  <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                    <div className="flex items-center">
                      <Icon className="mr-8" color="action">help_outline</Icon>
                      <Typography>{faq.question}</Typography>
                    </div>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className="pb-20">
                    <FaqContext.Provider value={contextValue}>
                      {faq.answer || faq.lead}
                    </FaqContext.Provider>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </FuseAnimateGroup>

          </Grid>
        </Grid>

      </div>
    </div>
  );
};

export default React.memo(FaqContent);
