import React from "react";
import Link from "next/link";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { accent, withAccent, withCapital } from "common/helpers";
import { productName } from "components/layout/common";
import { ExternalLink } from "components/common";
import { getHref } from "components/error/ui/config";
import { ExpandFaqId } from "components/faq";
import { pathNames } from "./routing";
import texts from "./texts";


const getSupportEmailHref = ({ supportEmailUri }) => getHref("Információkérés", supportEmailUri);

const cardId = 11;

const responsibilityId = 2;

const responsibilityKeywords = "jelszó jelszót jelszavam jelszóváltoztatás megváltoztatni e-mail chat chaten SMSben viber csevegő csevegés osztálytársnak évfolyamtárs évfolyamtársnak barát barátom barátomnak megosztás megosztani másolhatok adatlapon";

const responsibilityAnswer = (
  <div>
    <Typography className="mb-8">
      Annak érdekében, hogy a lehető leghatékonyabban védjük egymás adatait a visszaélésekkel és a felhasználók nem
      szándékos hibájából bekövetkező adatbiztonsági incidensekkel szemben, <b>kérjük, mindannyian tartsuk be a
      felelős használat alábbi 5 szabályát!</b>
    </Typography>

    <ol className="mb-0">
      <li className="mb-6"><span
        className="text-blue">Engedély nélkül ne osszunk meg mással semmilyen adatot! Akkor sem, ha az illető
            is alumnus.</span> Tartsuk tiszteletben, hogy az adatokat számunkra ugyan láthatóvá tették, de azok számára
        már nem biztos, akikkel mi épp megosztani szándékozzuk!
      </li>
      <li className="mb-6"><span className="text-blue">Csak olyan adatot másoljunk / töltsünk le az eszközünkre, amely
            helyi tárolására alapos indokunk van!</span> A kevésbé biztonságos helyi adattárolás helyett inkább bízd
        ránk az adatok tárolását! Ha újra szükséged lesz valamely adatra, a felületen bármikor újra megtekintheted.
      </li>
      <li className="mb-6"><span className="text-blue">{productName} fiókod jelszavát sose oszd meg mással: sem szóban,
            sem elektronikusan!</span> Ne küldd el se emailben, se SMS-ben, sem pedig csevegőalkalmazásban!
      </li>
      <li className="mb-6"><span
        className="text-blue">Ne használj olyan jelszót, amelyet más webhelyen is használsz!</span> Pl. ne az email
        fiókod vagy a Facebook fiókod jelszavát használd, hanem ezektől eltérő jelszót adj meg! Ha gondolod, használj
        jelszómenedzser alkalmazást, pl. a <ExternalLink href="https://www.lastpass.com/">LastPass</ExternalLink>-t! <i>(Jelszóváltoztatás
          a jobb felső sarokban lévő menüben.)</i>
      </li>
      <li className="mb-6"><span className="text-blue">Ne hagyd őrizetlenül az eszközt, amellyel éppen be
            vagy jelentkezve a felületre!</span> Ha már nem használod a webalkalmazást, jelentkezz ki! <i>(Kijelentkezés
        a jobb felső sarokban lévő menüben.)</i>
      </li>
    </ol>
  </div>
);


export const getFaqs = envVars => [
  {
    id: 1,
    question: "Biztonságban vannak az adataim?",
    lead: "Az Alumni Online! célja a hatékony kapcsolattartás elősegítése. Milyen kockázatokkal számoljak és mire figyeljek, ha felelősen szeretném használni a felületet?",
    otherKeywords: responsibilityKeywords,
    answer: (
      <div>
        <Typography className="mb-16">
          {withAccent(productName, true)} védelmi rendszere a legkorszerűbb webes technológiákkal készült, így az
          adatlopásra irányuló, eredményes támadások kockázata elenyésző. Attól tehát nem kell tartanod, hogy hackerek
          törik fel az oldalt, és ellopják az adataidat.
        </Typography>

        <Typography className="mb-16">
          Noha arra bátorítunk, hogy minél több információt ossz meg a diáktársakkal, lehetnek olyan adatok, amelyeket
          csak egy szűkebb körrel osztanál meg. Az <Link href={pathNames.alumnus.href}
                                                         as={pathNames.alumnus.as("me")}><a
          rel="noopener noreferrer">adatlapod</a></Link> szerkesztésekor <b>adat(csoport)onként külön-külön
          rendelkezhetsz arról, hogy az adott adat(csoport)ot kik számára kívánod láthatóvá tenni</b> (<i>mindenki /
          osztálytársak / évfolyamtársak / adminisztrátorok számára</i>).
        </Typography>

        {responsibilityAnswer}
      </div>
    ),
  },
  {
    id: responsibilityId,
    question: "A felelős használat 5 szabálya",
    lead: "Hogyan védjük egymás adatait a visszaélésekkel és a felhasználók nem szándékos hibájából bekövetkező adatbiztonsági incidensekkel szemben? Öt szabály, amit mindenképpen tartsunk be.",
    otherKeywords: responsibilityKeywords,
    answer: responsibilityAnswer,
  },
  {
    id: 3,
    question: "Hogyan tudom megváltoztatni a jelszavamat?",
    lead: (
      <span>A <Link href={pathNames.myAccount.href}><a rel="noopener noreferrer">jelszóváltoztatás</a></Link> a jobb
        felső sarokban található menüből érhető el.</span>
    ),
    otherKeywords: "jelszót jelszavam jelszóváltoztatás megváltoztatni",
  },
  {
    id: 4,
    question: "Mi legyen a jelszavam?",
    lead: "A beállított jelszónak egyrészt erősnek kell lennie. Másrészt fontos, hogy ne használj olyan jelszót, amit más webhelyen (pl. email fiókod, Facebook stb.) már megadtál!",
    otherKeywords: "e-mail jelszót chat chaten SMSben viber csevegő csevegés",
    answer: (
      <div>
        <Typography className="mb-16">
          A <ExpandFaqId faqId={responsibilityId}>felelős használat 5 szabályából</ExpandFaqId> kettő is a jelszavadra
          vonatkozik. Egyrészt, <b>ne használj olyan jelszót, amit más webhelyen már megadtál!</b> Pl. ne az email
          fiókod vagy a Facebook fiókod jelszavát használd, hanem ezektől eltérő jelszót adj meg!
        </Typography>

        <Typography className="mb-16">Másrészt, <b>{productName} fiókod jelszavát sose oszd meg mással: sem szóban, sem
          elektronikusan</b>! Ne küldd el se emailben, se SMS-ben, sem pedig csevegőalkalmazásban!
        </Typography>

        <Typography className="mb-16">
          Az internetes böngészés során általánosan is erősen javasolt, hogy <b>jelszavainkban ne szerepeljenek
          személyünkre, munkánkra, érdeklődési körünkre, életvitelünkre utaló adatok, kifejezések</b>. Így pl. a <i>születési
          évünk, családtagjaink neve, címünk, munkahelyünk</i> stb. Jó lehet viszont egy mondat, írásjelekkel. A legjobb
          pedig a véletlengenerált karaktersorozat.
        </Typography>

        <Typography className="mb-16">
          Azért, hogy ne kelljen megjegyezned a bonyolult és/vagy véletlenszerű karaktersorozatból álló jelszavaidat,
          az internetes böngészés során általánosan javasolt valamely jelszómenedzser alkalmazás használata
          (pl. <ExternalLink href="https://www.lastpass.com/">LastPass</ExternalLink>). Ezzel egy helyen tudod tárolni a
          különböző webhelyeken megadott &ndash; remélhetőleg különböző &ndash; jelszavakat anélkül, hogy bármelyiket is
          fejben kellene tartanod.
        </Typography>

        <Typography>
          <b>{withAccent(productName)} felületen csak erős jelszó állítható be.</b> Az űrlap csak akkor menthető, ha az
          általad megadott új jelszó megfelel a formai követelményeknek. Az egyes formai követelmények (pl. kisbetű,
          nagybetű, szám stb.) teljesülését a <Link href={pathNames.myAccount.href}><a
          rel="noopener noreferrer">jelszóváltoztatás</a></Link> űrlapján tudod ellenőrizni.
        </Typography>

      </div>
    ),
  },
  {
    id: 5,
    question: "Elküldhetem az osztálytársamnak egy közös osztálytársunk email címét vagy telefonszámát?",
    lead: "Engedély nélkül ne osszunk meg mással semmilyen adatot! Akkor sem, ha az illető is alumnus.",
    otherKeywords: "elküldeni e-mail osztálytársnak évfolyamtárs évfolyamtársnak barát barátom barátomnak megosztás megosztani",
    answer: (
      <div>
        <Typography className="mb-16">A <ExpandFaqId faqId={responsibilityId}>felelős használat 5
          szabályának</ExpandFaqId> egyike, hogy <b>engedély nélkül ne osszunk meg mással semmilyen adatot! Akkor sem,
          ha az illető is alumnus.</b></Typography>

        <Typography>Tartsuk tiszteletben, hogy az adatokat számunkra ugyan láthatóvá tették, de azok számára
          már nem biztos, akikkel mi épp megosztani szándékozzuk!
        </Typography>
      </div>
    ),
  },
  {
    id: 6,
    question: "Kimásolhatom / letölthetem magamnak az adatlapokon talált adatokat?",
    lead: "Csak olyan adatot másoljunk / töltsünk le az eszközünkre, amely esetében a helyi tárolásra alapos indokunk van! A helyi adattárolás ugyanis sokkal nagyobb biztonsági kockázatot jelent...",
    otherKeywords: "másolhatok adatlapon",
    answer: (
      <div>
        <Typography>A <ExpandFaqId faqId={responsibilityId}>felelős használat 5 szabályának</ExpandFaqId> egyike,
          hogy <b>csak olyan adatot másoljunk / töltsünk le az eszközünkre, amely esetében a helyi tárolásra alapos
            indokunk van!</b> A helyi adattárolás ugyanis sokkal nagyobb biztonsági kockázatot jelent. Ezért kérjük,
          hogy <b>a kevésbé biztonságos helyi adattárolás helyett inkább bízd ránk az adatok tárolását!</b> Ha újra
          szükséged lesz valamely adatra, azt a felületre bejlenetkezve bármikor újra megtekintheted.
        </Typography>
      </div>
    ),
  },
  {
    id: 7,
    question: "Miért van szükség az adataim megerősítésére?",
    lead: "A kezdőlapon azt a felhívást látod, hogy erősítsd meg adataidat? Egyik-másik adatlapon azt látod, hogy az adatok nem naprakészek, mert túl régóta nem történt adatmegerősítés?",
    otherKeywords: "megesősíteni megerősítése adatmegerősítés ellenőrzés adatellenőrzés ellenőrizni ellenőrzése elavult elavultak",
    answer: (
      <div>
        <Typography className="mb-16">A hatékony kapcsolattartás elősegítése végett célunk, hogy minél kevesebb
          kellemetlenség adódjon abból, hogy egy-egy alumnus adatlapon elavult elérhetőség vagy információ
          szerepeljen. <b>Arra kérünk, hogy legalább évente erősítsd meg kapcsolattartási adataidat!</b> Amikor a
          legutóbbi adatmegerősítés óta már több mint egy év eltelt, <b>a kezdőlapon automatikusan megjelenik az erre
            vonatkozó figyelmeztetés</b>, amelyre kattintva <b>kitöltheted az adatmegerősítő űrlapot</b>. Az
          adatmegerősítő űrlap beküldésekor az egy év újraindul.
        </Typography>

        <Typography className="mb-16"><b>A rendszer automatikus emailben is értesíteni fog, ha az adatmegerősítés
          esedékessé válik.</b></Typography>

        <Typography className="mb-16">Az adatmegerősítést az esedékességtől függetlenül bármikor elvégezheted. Az
          adatmegerősítő űrlapot a főmenüből megnyitva töltheted ki. </Typography>

        <Typography className="mb-8">Azért is érdemes legalább évente megerősíteni az adataidat, mert így az <Link
          href={pathNames.alumnus.href} as={pathNames.alumnus.as("me")}><a
          rel="noopener noreferrer">adatlapod</a></Link> fejlécében az alábbi megnyugtató üzenetet látják majd az azt
          megtekintő diáktársak:
        </Typography>

        <Alert severity="success" className="sm:max-w-288 mb-16">
          <Typography variant="caption">Nagy valószínűséggel naprakész adatok.</Typography>
        </Alert>

        <Typography className="my-8">Azok adatlapján, akik több mint 1 éve nem erősítették meg az adataikat, az alábbi
          üzenetet jelenik meg:
        </Typography>

        <Alert severity="warning" className="sm:max-w-288 mb-16">
          <Typography variant="caption">Az adatok nem feltétlenül naprakészek.</Typography>
        </Alert>

        <Typography className="my-8">Azok adatlapján pedig, akik több mint 2 éve nem erősítették meg az adataikat, az
          alábbi üzenetet jelenik meg:
        </Typography>

        <Alert severity="error" className="sm:max-w-288">
          <Typography variant="caption">Az adatok elavultak lehetnek.</Typography>
        </Alert>
      </div>
    ),
  },
  {
    id: 8,
    question: "Hol találom a súgót?",
    lead: "A fejléc jobb szélén található kérdőjel ikonra kattintva az aktuálisan megnyitott laphoz tartozó súgót nyithatod meg. (Nem mindegyik lapnál érhető el súgó.)",
    otherKeywords: "súgóban segítség",
  },
  {
    id: 9,
    question: "Hol olvashatok az Alumni híreiről, az általa szervezett programokról?",
    lead: (
      <span>
        Ezek az információk a webalkalmazáson kívül, az <ExternalLink
        href={envVars.publicSiteUrl}>Alumni weboldalán</ExternalLink> olvashatók.
      </span>
    ),
    otherKeywords: "hírek Bencés Bál öregdiáknap nótahajó koncert mentorprogram kosárkupa",
  },
  {
    id: 10,
    question: `Milyen kedvezményeket vehetek igénybe ${withAccent(texts.cardName)} felmutatásával?`,
    lead: (
      <span>
        Az Alumnusok által kínált kedvezményekre az <Link href={pathNames.search.href}>
        <a rel="noopener noreferrer">Alumnus keresőben</a>
      </Link>&nbsp;kereshetsz rá (az erre vonatkozó szűrővel). A főapátsági kedvezmények az <ExternalLink
        href={envVars.publicSiteUrl}>Alumni weboldalán</ExternalLink> olvashatók.
      </span>
    ),
    answer: (
      <div>
        <Typography className="mb-16">
          Az <Link href={pathNames.search.href}><a rel="noopener noreferrer">Alumnus
          keresőben</a></Link>, {accent(texts.discountProvidersName)} <span
          className="text-blue">{texts.discountProvidersName} szűrő</span> bekapcsolásával kereshetsz
          rá {withAccent(texts.discountNameAccusative)} felajánló diáktársakra. A kereső találati listája <b>a
          megfelelő keresőszavak beírásával szűrhető</b>. A találati kártyákon csak a kulcsszavak olvashatók. A
          kedvezménnyel kapcsolatos további információkról &ndash; a kártyára kattintva &ndash; az alumnus adatlapján
          tájékozódhatsz.
        </Typography>
        <Typography className="mb-16">
          A főapátsági kedvezményekkel kapcsolatos információk a webalkalmazáson kívül, az <ExternalLink
          href={envVars.publicSiteUrl}>Alumni weboldalán</ExternalLink> olvashatók.
        </Typography>
        <Typography>
          Te magad is <ExpandFaqId faqId={cardId}>nyújthatsz {texts.discountNameAccusative}</ExpandFaqId>.
        </Typography>
      </div>
    ),
    otherKeywords: "kedvezmény kedvezménnyújtó Alumni kártya kártyát kártyával Alumni-kártya kedvezményeket kedvezményt",
  },
  {
    id: cardId,
    question: `${withCapital(texts.cardName)} felmutatásával járó kedvezményt szeretnék nyújtani a diáktársaknak. Hol és hogyan jelezhetem ezt?`,
    lead: (
      <span>
        Az <Link href={pathNames.alumnus.href} as={pathNames.alumnus.as("me")}><a
        rel="noopener noreferrer">adatlapod</a></Link> szerkesztésekor, az <Link href={pathNames.alumnus.href}
                                                                                 as={`${pathNames.alumnus.as("me")}/edit/2/3`}>
        <a
          rel="noopener noreferrer">Együttműködés űrlapon</a></Link> jelölheted be, hogy {texts.discountNameAccusative}
        nyújtasz a diáktársaknak. Egyúttal kulcsszavakat és további informácikat adhatsz meg.
      </span>
    ),
    answer: (
      <div>
        <Typography className="mb-16">
          Az <Link href={pathNames.alumnus.href} as={pathNames.alumnus.as("me")}><a
          rel="noopener noreferrer">adatlapod</a></Link> szerkesztésekor, az <Link href={pathNames.alumnus.href}
                                                                                   as={`${pathNames.alumnus.as("me")}/edit/2/3`}>
          <a rel="noopener noreferrer">Együttműködés űrlapon</a></Link> jelölheted be,
          hogy {texts.discountNameAccusative} nyújtasz a diáktársaknak. Egyúttal kulcsszavak megadásával, valamint a
          leírás mezőben megadhatod a kedvezményhez kapcsolódó információkat. Az <Link href={pathNames.alumnus.href}
                                                                                       as={pathNames.alumnus.as("me")}><a
          rel="noopener noreferrer">adatlapodon</a></Link>&nbsp;<b>ilyenkor
          megjelenik {accent(texts.discountProviderName)} <i>{texts.discountProviderName}</i> címke, valamint az
          ehhez kapcsolódó információk</b>. Ezzel együtt az adatlapod az <Link href={pathNames.search.href}><a
          rel="noopener noreferrer">Alumnus keresőben</a></Link>, {accent(texts.discountProvidersName)} <span
          className="text-blue">{texts.discountProvidersName} szűrő</span> bekapcsolásával is megjelenik a találati
          listában.
        </Typography>
        <Typography>
          A kereső találati listája <b>a megfelelő keresőszavak beírásával szűrhető</b>. A találati kártyákon csak a
          kulcsszavak olvashatók. A kedvezménnyel kapcsolatos további információkról &ndash; a kártyára
          kattintva &ndash; az alumnus adatlapján tájékozódhatsz.
        </Typography>
      </div>
    ),
    otherKeywords: "kedvezmény kedvezménnyújtó Alumni kártya kártyát kártyával Alumni-kártya kedvezményeket kedvezményt",
  },
  {
    id: 12,
    question: "Milyen módon vehetem fel a kapcsolatot az Alumnival?",
    lead: (
      <span>
        Ha olyan kérdésed merül fel, amelyre sem a súgóban, sem a gyakran ismételt kérdések oldalán nem találtál
        választ, írj egy üzenetet {accent(envVars.supportEmail)} <ExternalLink href={getSupportEmailHref(envVars)}
                                                                               noIcon>{envVars.supportEmail}</ExternalLink> email címre.
      </span>
    ),
    otherKeywords: "kapcsolat kapcsolatfelvétel e-mail",
  },
  {
    id: 13,
    question: "Hogyan tudok kijelentkezni?",
    lead: "Kijelentkezni a jobb felső sarokban található menüből lehet. Kérjük, ha már nem használod a webalkalmazást, mindig jelentkezz ki!",
    otherKeywords: "kijelentkezés kilépés kilépni",
    answer: (
      <div>
        <Typography className="mb-16">
          Kijelentkezni a jobb felső sarokban található menüből lehet.
        </Typography>
        <Typography>
          <b>Biztonsági megfontolások alapján kérjük, ha már nem használod a webalkalmazást, mindig jelentkezz
            ki!</b> Ezzel együtt a rendszer 60 perc inaktivitás után automatikusan is kijelentkeztet.
        </Typography>
      </div>
    ),
  },
];
