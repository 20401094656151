import React, { useState } from "react";
import { FusePageSimple } from "components/fuse";
import Layout from "components/layout";
import FaqContent from "./FaqContent";
import FaqHeader from "./FaqHeader";


const FaqPage = () => {

  const [filteredData, setFilteredData] = useState([]);

  return (
    <Layout>
      <FusePageSimple
        content={
          <div className="w-full flex flex-col flex-auto">
            <FaqHeader setFilteredData={setFilteredData}/>
            <FaqContent filteredData={filteredData}/>
          </div>
        }
      />
    </Layout>
  );
};

export default React.memo(FaqPage);
