import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import reactToString from "react-to-string";
import { Icon, Input, Paper, Typography } from "@material-ui/core";
import { getFaqs } from "config/faqs";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { FuseAnimate } from "components/fuse";
import { useStyles } from "./styles";


const searchInObj = (itemObj, searchText) => Object.values(itemObj || {})
  .find(value => clsx(value && reactToString(value)).toLowerCase().includes(searchText));


const filterArrayByString = (mainArr, searchText) => {
  searchText = clsx(searchText).trim().toLowerCase();
  if (searchText === "") return mainArr;
  return mainArr.filter(itemObj => searchInObj(itemObj, searchText));
};


const FaqHeader = ({ setFilteredData }) => {

  const { envVars } = useContext(SessionContext);


  const [searchText, setSearchText] = useState("");


  const faqs = useMemo(() => getFaqs(envVars), [envVars]);


  const handleSearch = useCallback(event => setSearchText(event.target.value), []);


  useEffect(() => {
    function getFilteredArray(arr, searchText) {
      if (searchText.length === 0) {
        return arr;
      }
      return filterArrayByString(arr, searchText);
    }

    setFilteredData(getFilteredArray(faqs, searchText));
  }, [faqs, searchText, setFilteredData]);


  const classes = useStyles();


  return (
    <div
      className={clsx(classes.header, "flex flex-col flex-shrink-0 items-center justify-center text-center p-16 sm:px-24 h-200 md:h-224 xl:h-256")}>

      <FuseAnimate animation="transition.slideUpIn" duration={400} delay={100}>
        <Typography color="inherit" className="text-32 md:text-40 xl:text-48 font-light">
          Segíthetünk?
        </Typography>
      </FuseAnimate>

      <Paper className={"flex items-center h-56 w-full max-w-md mt-16 sm:mt-32"} elevation={1}>
        <Icon color="action" className="ml-16">search</Icon>
        <Input
          placeholder="Keresés..."
          className="px-16"
          disableUnderline
          fullWidth
          inputProps={{
            "aria-label": "Keresés"
          }}
          value={searchText}
          onChange={handleSearch}
        />
      </Paper>
    </div>
  );
};

export default React.memo(FaqHeader);
