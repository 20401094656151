import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles(theme => ({
  header: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  panel: {
    margin: 0,
    width: "100%",
    borderWidth: "0px 1px 0 1px",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "&:first-child": {
      borderRadius: "16px 16px 0 0",
      borderWidth: "1px 1px 0 1px",
    },
    "&:last-child": {
      borderRadius: "0 0 16px 16px",
      borderWidth: "0 1px 1px 1px",
    },
    "&$expanded": {
      margin: "auto",
      borderWidth: "1px",
    },
  },
  expanded: {},
}));
