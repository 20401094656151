import React, { useCallback, useContext } from "react";
import FaqContext from "./FaqContext";


const ExpandFaqId = ({ faqId, children }) => {

  const { openExpansion } = useContext(FaqContext);

  const onClick = useCallback(() => faqId && openExpansion && openExpansion(faqId), [faqId, openExpansion]);

  return (
    <a onClick={onClick} className="cursor-pointer">{children}</a>
  )
};

export default React.memo(ExpandFaqId);
